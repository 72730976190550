<template>
  <div>
    <b-row>
      <b-col v-if="tour.ship_call.cruise.ship" md="4" sm="6">
        <strong>Ship:</strong> {{tour.ship_call.cruise.ship.name}}
      </b-col>
      <b-col md="4" sm="6" v-if="tour.date">
        <strong>Date:</strong> {{tour.date | formatDate({short: true})}}
      </b-col>
      <b-col v-if="tour.ship_call.docking_start" md="4" sm="6">
        <strong>Docking Time:</strong> {{tour.ship_call.docking_start | formatTime}} - {{tour.ship_call.docking_end | formatTime}}
      </b-col>
      <b-col md="4" sm="6" v-if="tour.ship_call.cruise.code">
        <strong>Cruise:</strong> {{tour.ship_call.cruise.code}} ({{tour.ship_call.cruise.ship.line.name}})
      </b-col>
      <b-col md="4" sm="6" v-if="tour.tour_nr">
        <strong>Tour Nr:</strong> {{tour.tour_nr}}
      </b-col>
      <b-col v-if="tour.language" md="4" sm="6">
        <strong>Language:</strong> {{tour.language.name}}
      </b-col>
      <b-col v-if="tour.approx_guests" md="4" sm="6">
        <strong>Approx. Guests:</strong> {{tour.approx_guests}}
      </b-col>
    </b-row>
    <hr/>
    <div class="row">
      <div v-if="location.time || location.location" v-for="(location) in timedLocations" class="col-md-4 col-sm-6">
        <dl>
          <dt>{{location.label}}</dt>
          <dd>
            <strong>{{location.time | formatTime}}</strong>
            {{location.location}}
          </dd>
        </dl>
      </div>
    </div>
    <div class="alert alert-info" v-if="(tour && tour.ship_call.cruise.additional_info) || (tour && tour.ship_call.additional_info) || (tour && tour.additional_info) || (staff && staff.additional_info)">
      <strong>Additional Info</strong>
      <wrapped-text v-if="tour && tour.ship_call.cruise.additional_info" label="Cruise" :text="tour.ship_call.cruise.additional_info" />
      <wrapped-text v-if="tour && tour.ship_call.additional_info" label="Ship Call" :text="tour.ship_call.additional_info" />
      <wrapped-text v-if="tour && tour.additional_info" label="Tour" :text="tour.additional_info" />
      <wrapped-text v-if="staff && staff.additional_info" label="Staff" :text="staff.additional_info" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, Prop} from 'vue-property-decorator'
  import {Tour} from "@eguide/api";
  import {TourStaff} from "@eguide/api";
  import WrappedText from "@hc/ui/util/components/WrappedText.vue";
  @Component({
    components: {WrappedText}
  })
  export default class TourInfo extends Vue{
    @Prop({type: Object, required: true}) tour!:Tour
    @Prop({type: Object}) tourStaff:TourStaff

    get staff() {
      return this.tourStaff || this.tour.staff[0]
    }

    get timedLocations() {
      if(this.staff){
        return [
          {label: 'Meeting', time: this.staff?.meeting_time, location: this.staff?.start_location?.name},
          {label: 'Start', time: this.staff?.start_time, location: this.staff?.start_location?.name},
          {label: 'End', time: this.staff?.end_time, location: this.staff?.end_location?.name},
          {label: 'Leisure', time: this.tour.leisure_time, location: this.tour.leisure_location?.name}
        ]
      } else {
        return [
          {label: 'Start', time: this.tour?.start_time, location: this.tour?.start_location?.name},
          {label: 'End', time: this.tour?.end_time, location: this.tour?.end_location?.name},
          {label: 'Leisure', time: this.tour.leisure_time, location: this.tour.leisure_location?.name}
        ]
      }

    }
  }
</script>

<style scoped>

</style>
